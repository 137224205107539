<template>
  <div>
    <CCard color="white" text-color="black" class="text-center col-md-12 shadow-sm" body-wrapper>
      <div class="row">
        <div class="col-md-10 text-left">
          <span class="h2">
            <strong class="font-weight-normal">{{ data.title }} </strong>
            <strong class="h4 text-danger" v-if="data.deleted_at != null">
              * {{ $t('voucherIsCanceled') }}
            </strong>
          </span>
        </div>
        <div class="col-md-2 text-right">
          <a @click="$router.go(-1)">
            <CButton>
              <CIcon name="cil-arrow-thick-from-right" />
              {{ $t('back') }}
            </CButton>
          </a>
        </div>
      </div>
      <hr />
      <CCard border-color="greyborder" class="box">
    <CRow>
      <CCol md="12" >
        <div class="row" >
        
          <div class="col" >
                <img :src="data.imgUrl" alt="Voucher Image" class="img-fluid voucherimg">
          </div>
              <div class="col-md-6 col-sm-12" >
                <div class="p-3" :style="{
              'background-image': `url(${vcbackground})`,
              'background-size': '100%',
            }">
                  <h5 class="font-weight-normal">{{ data.title }}</h5>
                  <h1 class="font-weight-normal text-center text-grey" style="font-size:75px">
                    {{ data.value }}
                  </h1>
                  <span style="font-size:12px" class="text-decription font-weight-normal">
                    {{ data.description }}
                  </span>
                  <div class="row mt-3">
                    <div class="col-6 text-left">
                    <span class="font-weight-normal text-dark"> 
                      NO. 0000001 
                    </span><br><br>
                    <span style="border:1px solid;padding:4px;">
                      CODE : pKh3xVL6
                    </span>
                  </div>
                    <div style="text-align: right;" class="col-6">
                            <span style="font-size:12px" class="text-dark">
                                SERIAL NO. {{ data.serialNumber }}
                            </span><br><br>
                            <span style="font-size:12px;padding:4px;">
                                EXPIRE : {{ expireDate }}
                            </span>
                        </div>
                    </div>
                </div>
              </div>
        </div>
      </CCol>
    </CRow>
  </CCard>

      <CRow>
        <CCol md="6" class=" text-left">
          <CCardBody border-color="greyborder">
            <h2 class="text-success font-weight-normal">
              {{ data.sendVouchersTotal }} {{ $t('item') }}
            </h2>
            <br />
            <h5 class="text-dark"><em class="far fa-credit-card"></em> {{ $t('allVoucher') }}</h5>
          </CCardBody>
        </CCol>
        <CCol md="6" class="text-left">
          <CCardBody border-color="greyborder">
            <h2 class="text-danger font-weight-normal">
              {{ data.isUsedTotal }} {{ $t('item') }}
            </h2>
            <br />
            <h5 class="font-weight-normal text-dark"><em class="fa fa-calendar-check-o"></em> {{ $t('used') }}</h5>
          </CCardBody>
        </CCol>
      </CRow>
      <br />

      <CRow v-if="data.deleted_at === null">
        <CCol md="3">
          <json-csv :data="csvdata" :name="data.serialNumber + '.csv'">
            <CButton color="info" block class="text-white">
              <em class="fas fa-file-csv"></em> {{ $t('export') }} Voucher NO. (csv)
            </CButton>
          </json-csv>
        </CCol>
        <CCol md="3">
          <CButton block class="btn-primary" @click="printAllVoucher()" v-if="loadingButton === true">
            <em class="fas fa-print"></em> {{ $t('printVoucher') }}
          </CButton>
          <CButton block class="btn-primary" disabled v-else>
            <CSpinner color="light" size="sm" />
            {{ $t('printVoucher') }}
          </CButton>
        </CCol>
        <CCol md="3">
          <CButton color="success" block class="text-white" @click="allExtendTimeModal = true">
            <em class="fa fa-calendar-check-o"></em> {{ $t('extendDuration') }}
          </CButton>
        </CCol>
        <CCol md="3">
          <CButton class="btn-outline-danger btn-block" @click="popupModal = true">
            <CIcon name="cil-trash"></CIcon> {{ $t('cancelThisVoucher') }}
          </CButton>
        </CCol>
      </CRow>

      <br />
      <CDataTable :items="items" :fields="fields" hover border>
        <template #isUsed="{ item }">
          <td>
            <CBadge :color="getBadge(item.isUsed)">
              <span class="font-weight-normal">&nbsp;{{ item.isUsed }}&nbsp;</span>
            </CBadge>
          </td>
        </template>
        <template #print="{ item }">
          <td class=" text-center">
            <CButton class="m-1" v-if="item.isUsed === $t('availableCrm')" color="success" @click="extendTimeHandle(item)" size="sm">
              <em class="far fa-calendar-plus"></em>
            </CButton>
            <CButton class="m-1" v-else color="success" @click="extendTimeHandle(item)" size="sm" disabled>
              <em class="far fa-calendar-plus"></em>
            </CButton>
            <CButton color="info" class="text-white" @click="historyHandle(item)" size="sm">
              <em class="fas fa-history"></em>
            </CButton>
            <CButton class="m-1" color="primary" @click="printVoucher(item)" size="sm">
              <em class="fa fa-print"></em>
            </CButton>
          </td>
        </template>
      </CDataTable>
      <pagination :meta_data="meta_data" v-on:next="getVoucherRedemption">
      </pagination>
    </CCard>
    <CModal :show.sync="popupModal" color="danger" :title="$t('cancelVoucher')" centered header="false" :footer="footer">
      <CRow class="justify-content-center col-md-12">
        <CCard color="white" text-color="dark" class="text-center col-md-12">
          <h4>{{ $t('cancelThisVoucherDialog') }}</h4>
          <h6>
            {{ $t('cancelThisVoucherDialog2') }}
          </h6>
        </CCard>
      </CRow>
      <template #footer-wrapper>
        <CRow class="justify-content-center">
          <CCol md="4" v-if="loadingButton === true">
            <CButton block color="danger" @click="deleteVoucher()">
              {{ $t('submit') }}
            </CButton>
          </CCol>
          <CCol md="4" v-else-if="loadingButton === false">
            <CButton block color="danger" disabled>
              <CSpinner color="light" size="sm" /> {{ $t('loading') }}
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="light" @click="popupModal = false">
              {{ $t('back') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
      </template>
    </CModal>
    <CModal :show.sync="allExtendTimeModal" centered header="false" :footer="footer" :title="$t('extendDuration')">
      <CRow class="justify-content-center">
        <CCol md="12">
          <CCard color="white" text-color="dark" border-color="light">
            <CCardBody>
              <h4 class="font-weight-normal">
                {{ data.title }}
              </h4>
              <p>{{ data.description }}</p>
              <br />
              <div class="form-group row">
                <label class="col-sm-4 col-form-label text-left text-black">
                  <input type="checkbox" v-model="isExpirationPreVoucher" true-value="true" false-value="false" />
                  {{ $t('setPeriod') }}
                </label>
                <div class="col-sm-8">
                  <CInput v-if="isExpirationPreVoucher === 'false' ||
                    isExpirationPreVoucher === ''
                    " class="text-black " disabled />

            <v-md-date-range-picker v-else opens="left"  :currentLang="getCurrentLang()"
                :locale="getCurrentLang()" :presets="updatePresetsRangePicker()" v-model="durations" @change="handleChangeDate" style="width: 100%;">
                <template slot="input" class="activator-wrapper">
                  <input type="text" readonly="readonly" style="background-color: white;"
                    class="form-control input-group-text" :value="durationText" :placeholder="$t('selectDate')" />
                </template>
              </v-md-date-range-picker>
                  <p class="font-weight-normal text-danger" style="font-size:10px"
                    v-if="isExpirationPreVoucher === 'true'">
                    {{ alertMessage }}
                  </p>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-form-label text-left text-black">
                  <input type="checkbox" v-model="isExpirationPreVoucher" true-value="false" false-value="true" />
                  {{ $t('noLimitTimeofUse') }}
                </label>
              </div>
              <span style="font-size:10px">
                * {{ $t('extendAllVoucher') }}
              </span>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <template #footer-wrapper>
        <CRow class="justify-content-center">
          <CCol md="4" v-if="loadingExtend === true">
            <CButton block color="success" @click="extendDateUpdateAllPreVoucher()">
              {{ $t('submit') }}
            </CButton>
          </CCol>
          <CCol md="4" v-else-if="loadingExtend === false">
            <CButton block color="success" disabled>
              <CSpinner color="light" size="sm" /> {{ $t('loading') }}
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="light" @click="allExtendTimeModal = false">
              {{ $t('cencel') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
      </template>
    </CModal>
    <CModal :show.sync="extendTimeModal" centered header="false" :footer="footer" :title="$t('extendDuration')">
      <CRow class="justify-content-center">
        <CCol md="12">
          <CCard color="white" text-color="dark" border-color="light">
            <CCardBody>
              <h4 class="font-weight-normal">
                Voucher NO. {{ voucherNOExtend }}
              </h4>
              <br />
              <div class="form-group row">
                <label class="col-sm-4 col-form-label text-left text-black">
                  <input type="checkbox" v-model="isExpiration" true-value="true" false-value="false" />
                  {{ $t('setPeriod') }}
                </label>
                <div class="col-sm-8">
                  <CInput v-if="isExpiration === 'false' || isExpiration === ''" class="text-black " disabled />
                  <v-md-date-range-picker v-else opens="left"  :currentLang="getCurrentLang()"
                :locale="getCurrentLang()" :presets="updatePresetsRangePicker()" v-model="extendDate" @change="handleChangeDate" style="width: 100%;">
                <template slot="input" class="activator-wrapper">
                  <input type="text" readonly="readonly" style="background-color: white;"
                    class="form-control input-group-text" :value="extendDateText" :placeholder="$t('selectDate')" />
                </template>
              </v-md-date-range-picker>
                  <p class="font-weight-normal text-danger" style="font-size:10px" v-if="isExpiration === 'true'">
                    {{ alertMessage }}
                  </p>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-form-label text-left text-black">
                  <input type="checkbox" v-model="isExpiration" true-value="false" false-value="true" />
                  {{ $t('noLimitTimeofUse') }}
                </label>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <template #footer-wrapper>
        <CRow class="justify-content-center">
          <CCol md="4" v-if="loadingExtend === true">
            <CButton block color="success" @click="extendDateUpdate()">
              {{ $t('submit') }}
            </CButton>
          </CCol>
          <CCol md="4" v-else-if="loadingExtend === false">
            <CButton block color="success" disabled>
              <CSpinner color="light" size="sm" /> {{ $t('loading') }}
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="light" @click="extendTimeModal = false">
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
      </template>
    </CModal>
    <CModal :show.sync="historyModal" centered header="false" :footer="footer" :title="$t('editHistory')">
      <CRow class="justify-content-center">
        <CCard color="white" text-color="dark" class="col-md-12">
          <CDataTable :items="histotyItems" :fields="historyFields" hover border></CDataTable>
        </CCard>
      </CRow>
      <template #footer-wrapper>
        <CRow class="justify-content-center col-md-12"> </CRow>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import crm from '@/services/crm'
import moment from 'moment'
import JsonCsv from 'vue-json-csv'
import printV from '@/util/print'
import report from '@/services/report'
import Pagination from '@/containers/Pagination'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    JsonCsv,
    Pagination,
    DatePicker,
  },
  data() {
    return {
      data: {},
      popupModal: false,
      footer: '',
      loadingButton: true,
      loadingExtend: true,
      detail: [],
      csvdata: [],
      printdata: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
      },
      objectIds: [],
      extendTimeModal: false,
      isExpiration: '',
      startAtExtendTime: '',
      endAtExtendTime: '',
      extendDate: [new Date(), new Date()],
      voucherNOExtend: '',
      alertMessage: '',
      voucherRedemptionObjectId: '',
      voucherRedemptionId: '',
      editedData: [],
      allExtendTimeModal: false,
      isExpirationPreVoucher: '',
      durations: [],
      historyModal: false,
      histotyItems: [],
      vcbackground: '/../img/pvc2.jpg',
      dateText: null,
      durationText:null,
      extendDateText:null,
    }
  },
  computed: {
    ...mapGetters(['shopObjectId', 'users']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    objectId() {
      return this.$route.params.objectId
    },
    createdAt() {
      return moment(this.data.created_at).format('LLL')
    },
    expireDate() {
      if (this.data.endAt === '') {
        return 'ไม่ระบุ'
      } else {
        return moment(this.data.endAt).format('DD/MM/YYYY')
      }
    },
    fields() {
      if (this.data.deleted_at != null) {
        return [
          {
            key: 'index',
            label: '#',
            _classes: 'text-left font-weight-normal text-dark',
          },
          {
            key: 'date',
            label: this.$i18n.t('createAt'),
            _classes: 'text-left font-weight-normal text-dark',
          },
          {
            key: 'voucherNO',
            label: 'Voucher NO.',
            _classes: 'text-right font-weight-normal text-dark',
          },
          {
            key: 'voucherCode',
            label: 'Voucher Code.',
            _classes: 'text-right font-weight-normal text-dark',
          },
          { key: 'isUsed', label: this.$i18n.t('status'), _classes: 'text-center font-weight-normal text-dark' },
          { key: 'endAt', label: this.$i18n.t('expiryDate'), _classes: 'text-right font-weight-normal text-dark' },
          {
            key: 'printed',
            label: this.$i18n.t('printCount'),
            _classes: 'text-right font-weight-normal text-dark',
          },
        ]
      } else {
        return [
          {
            key: 'index',
            label: '#',
            _classes: 'text-left font-weight-normal text-dark',
          },
          {
            key: 'date',
            label: this.$i18n.t('createAt'),
            _classes: 'text-left font-weight-normal text-dark',
          },
          {
            key: 'voucherNO',
            label: 'Voucher NO.',
            _classes: 'text-right font-weight-normal text-dark',
          },
          {
            key: 'voucherCode',
            label: 'Voucher Code.',
            _classes: 'text-right font-weight-normal text-dark',
          },
          { key: 'isUsed', label: this.$i18n.t('status'), _classes: 'text-center font-weight-normal text-dark' },
          { key: 'endAt', label: this.$i18n.t('expiryDate'), _classes: 'text-right font-weight-normal text-dark' },
          {
            key: 'printed',
            label: this.$i18n.t('printCount'),
            _classes: 'text-right font-weight-normal  text-dark',
          },
          /*           { key: 'extendTime', label: '', _classes: 'text-center' },
           */ {
            key: 'print',
            label: '',
            _classes: 'text-center font-weight-normal  text-dark',
            _style: 'width:15%',
          },
        ]
      }
    },
    items() {
      this.objectIds = []
      let detail = this.detail
      let info = []
      let excsv = []
      let isUsed = ''
      let exp = ''
      let strt = ''
      let endExtend = ''
      let startExtend = ''
      let i = 1
      let start = this.meta_data.current_page * 50 - 50
      let printed = 0
      let voucherNO = ''

      for (let data of detail) {
        if (data.isUsed === true) {
          isUsed = this.$i18n.t('used')
        } else if (data.isUsed === false) {
          isUsed = this.$i18n.t('availableCrm')
        }

        if (data.startAt === '') {
          strt = this.$i18n.t('unspecified')
          startExtend = ''
        } else {
          strt = moment(String(data.startAt)).format('DD/MM/YYYY')
          startExtend = data.startAt
        }

        if (data.endAt === '') {
          exp = this.$i18n.t('unspecified')
          endExtend = ''
        } else {
          exp = moment(String(data.endAt)).format('DD/MM/YYYY')
          endExtend = data.endAt
        }

        if (data.isPrinted !== undefined) {
          printed = data.isPrinted
        } else {
          printed = 0
        }

        if (data.voucherNO !== undefined) {
          voucherNO = data.voucherNO
        } else {
          voucherNO = ''
        }

        info.push({
          index: start + i,
          id: data._id || data.id,
          objectId: data.objectId,
          date: moment(String(data.created_at)).format('DD/MM/YYYY'),
          voucherNO: voucherNO,
          voucherCode: data.voucherCode,
          isUsed: isUsed,
          startAt: strt,
          endAt: exp,
          startAtExtend: startExtend,
          endAtExtend: endExtend,
          printed: printed,
          edited: data.edited,
        })

        excsv.push({
          '#': i,
          'Voucher NO.': data.voucherCode,
          วันหมดอายุ: exp,
        })
        this.objectIds.push(data.objectId)
        i++
      }

      this.csvdata = excsv
      this.printdata = info

      return info
    },
    historyFields() {
      return [
        {
          key: 'index',
          label: '#',
          _classes: 'text-left font-weight-normal text-dark',
        },
        {
          key: 'duration',
          label: this.$i18n.t('duration'),
          _classes: 'text-center font-weight-normal  text-dark',
        },
        { key: 'updatedAt', label: this.$i18n.t('editAt'), _classes: 'text-center font-weight-normal  text-dark' },
      ]
    },
  },
  mounted() {
    this.getVoucher()
    this.getVoucherRedemption()
  },
  methods: {
    durationsRange(){
      let dateText = moment(this.durations[0])
                  .locale('en')
                  .format('DD/MM/YYYY') + " - " +
                  moment(this.durations[1])
                    .locale('en')
                    .format('DD/MM/YYYY')

                    return dateText
    },
    getRange(startOffset = 0, endOffset = 0, period = 'day') {
      return [moment().subtract(startOffset, period).startOf(period), moment().subtract(endOffset, period).endOf(period)];
    },
    updatePresetsRangePicker() {
      return [{
        label: this.$i18n.t('today'),
        range: this.getRange(0, 0)
      }, {
        label: this.$i18n.t('yesterday'),
        range: this.getRange(1, 1)
      }, {
        label: this.$i18n.t('last7days'),
        range: this.getRange(6, 0)
      }, {
        label: this.$i18n.t('last30days'),
        range: this.getRange(29, 0)
      }, {
        label: this.$i18n.t('thismonth'),
        range: this.getRange(0, 0, 'month')
      }, {
        label: this.$i18n.t('lastmonth'),
        range: this.getRange(1, 1, 'month')
      }]
    },
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0))
    },
    historyHandle(item) {
      this.historyModal = true
      this.histotyItems = []

      let data = item.edited
      let datetime = ''
      let j = 1

      if (data !== undefined && data.length > 0) {
        for (let itemm of data) {
          if (itemm.startAt !== '') {
            datetime =
              moment(String(itemm.startAt)).format('DD/MM/YYYY') +
              ' ถึง ' +
              moment(String(itemm.endAt)).format('DD/MM/YYYY')
          } else {
            datetime = 'ไม่ระบุ'
          }

          this.histotyItems.push({
            index: j,
            duration: datetime,
            updatedAt: moment(String(itemm.updatedAt)).format(
              'DD/MM/YYYY HH:mm'
            ),
          })
          j++
        }
      }
    },
    extendDateUpdateAllPreVoucher() {
      if (this.isExpirationPreVoucher === 'true') {
        if (this.durations[0] === null || this.data.startAt === '') {
          this.alertMessage = this.$i18n.t('validDateInput')
        }
      } else if (this.isExpirationPreVoucher === 'false') {
        this.data.startAt = ''
        this.data.endAt = ''
        this.alertMessage = ''
      }

      let data = {
        startAt: this.data.startAt,
        endAt: this.data.endAt,
        edited: {
          startAt: this.data.startAt,
          endAt: this.data.endAt,
          updatedAt: new Date(),
          updatedBy: {
            email: this.users.email,
            shopName: this.users.shopName,
          },
        },
      }

      if (this.alertMessage === '') {
        this.loadingExtend = false
        crm
          .post(
            '/api/v1.0/' + this.uid + '/voucher/' + this.objectId + '/edited',
            data
          )
          .then((res) => {
            if (res.data.error.code === 0) {
              this.loadingExtend = true
              this.allExtendTimeModal = false
              this.$router.go(this.$router.currentRoute)
            } else {
              alert(this.$i18n.t('errorAlert'))
            }
          })
          .catch((e) => {
            console.log(e)
          })
      }
    },
    extendDateUpdate() {
      if (this.isExpiration === 'true') {
        if (this.extendDate[0] === null || this.startAtExtendTime === '') {
          this.alertMessage = this.$i18n.t('validDateInput')
        }
      } else if (this.isExpiration === 'false') {
        this.startAtExtendTime = ''
        this.endAtExtendTime = ''
        this.alertMessage = ''
      }

      let dataForEdited = {
        startAt: this.startAtExtendTime,
        endAt: this.endAtExtendTime,
        updatedAt: new Date(),
        updatedBy: {
          email: this.users.email,
          shopName: this.users.shopName,
        },
      }

      if (
        this.editedData === undefined ||
        this.editedData.length === undefined
      ) {
        this.editedData = []
        this.editedData.push(dataForEdited)
      } else if (this.editedData.length >= 0) {
        this.editedData.push(dataForEdited)
      }

      if (this.alertMessage === '') {
        this.loadingExtend = false
        let data = {
          uid: this.uid,
          objectId: this.voucherRedemptionObjectId,
          id: this.voucherRedemptionId,
          startAt: this.startAtExtendTime,
          endAt: this.endAtExtendTime,
          edited: this.editedData,
        }

        crm
          .post('/api/v1.0/' + this.uid + '/voucher/send', data)
          .then((res) => {
            if (res.data.error.code === 0) {
              this.loadingExtend = true
              this.extendTimeModal = false
              this.$router.go(this.$router.currentRoute)
            } else {
              alert(this.$i18n.t('errorAlert'))
            }
          })
          .catch((e) => {
            console.log(e)
          })
      }
    },
    extendTimeHandle(item) {
      this.voucherRedemptionObjectId = item.objectId
      this.voucherRedemptionId = item.id
      this.extendTimeModal = true
      this.voucherNOExtend = item.voucherNO
      this.editedData = item.edited

      if (item.startAtExtend === '' && item.endAtExtend === '') {
        this.isExpiration = 'false'
      } else {
        this.isExpiration = 'true'
        this.extendDate = [
          new Date(item.startAtExtend),
          new Date(item.endAtExtend),
        ]
        this.extendDateText = moment(this.extendDate[0])
                  .locale('en')
                  .format('DD/MM/YYYY') + " - " +
                  moment(this.extendDate[1])
                    .locale('en')
                    .format('DD/MM/YYYY')
        this.handleChange(this.extendDate)
      }
    },
    getCurrentLang() {
                return this.$i18n.locale
              },
    handleChangeDate(values) {
        const date = {
         start: moment(values[0]).toISOString(),
        end: moment(values[1]).toISOString(),
          }
        this.durationText = moment(values[0])
                  .locale('en')
                  .format('DD/MM/YYYY') + " - " +
                  moment(values[1])
                    .locale('en')
                    .format('DD/MM/YYYY')
                    this.startAtExtendTime = date.start
      this.endAtExtendTime = date.end
      this.data.startAt = date.start
      this.data.endAt = date.end
      this.alertMessage = ''
   },
    handleChange(values) {
      let date = {
        start: moment(values[0]).toISOString(),
        end: moment(values[1]).toISOString(),
      }
      this.startAtExtendTime = date.start
      this.endAtExtendTime = date.end
      this.data.startAt = date.start
      this.data.endAt = date.end
      this.alertMessage = ''
    },
    getBadge(isUsed) {
      switch (isUsed) {
        case 'ใช้แล้ว':
        case this.$i18n.t('used'):
          return 'success'
        case 'ยังไม่ใช้':
        case this.$i18n.t('availableCrm'):
          return 'danger'
        default:
          return 'primary'
      }
    },
    getVoucher() {
      const uid = this.uid
      let objectId = this.objectId

      crm
        .get('/api/v1.0/' + uid + '/voucher/get/' + objectId)
        .then((res) => {
          this.data = res.data.data.documents
          console.log(this.data)

          if (this.data.startAt === '' && this.data.endAt === '') {
            this.isExpirationPreVoucher = 'false'
          } else {
            this.isExpirationPreVoucher = 'true'

            this.durations = [
              new Date(this.data.startAt),
              new Date(this.data.endAt),
            ]
            this.durationText = moment(this.durations[0])
                  .locale('en')
                  .format('DD/MM/YYYY') + " - " +
                  moment(this.durations[1])
                    .locale('en')
                    .format('DD/MM/YYYY')
              
          }

          if (this.data.imgUrl === null) {
            this.data.imgUrl =
              'https://cdn.pixabay.com/photo/2014/11/27/22/44/gift-548291_1280.jpg'
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    getVoucherRedemption(page = 1) {
      const uid = this.uid
      let voucherObjectId = this.objectId
      let params = {
        page: page,
        limit: 50,
      }

      crm
        .get(
          '/api/v1.0/' +
          uid +
          '/getredemptionbyvoucherobjectId/' +
          voucherObjectId,
          { params: params }
        )
        .then((res) => {
          if (res.data.data !== undefined && res.data.data.length != 0) {
            this.detail = res.data.data
            this.meta_data.last_page = res.data.paginate.pageCount
            this.meta_data.current_page = res.data.paginate.currentPage
          } else {
            this.detail = []
          }
        })
    },
    deleteVoucher() {
      this.loadingButton = false
      const uid = this.uid
      let objectId = this.objectId

      crm
        .post('/api/v1.0/' + uid + '/voucher/delete/' + objectId)
        .then((res) => {
          if (res.data.error.code === 0) {
            this.cancelVoucher()
          } else {
            alert('มีบางอย่างผิดพลาด กรุณาตรวสอบข้อมูลอีกครั้ง!')
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    cancelVoucher() {
      this.loadingButton = false
      const uid = this.uid
      let objectId = this.objectId

      crm
        .post('/api/v1.0/' + uid + '/voucher/' + objectId + '/cancel')
        .then((res) => {
          if (res.data.error.code === 0) {
            this.popupModal = false
            this.loadingButton = true
            this.getVoucher()
            this.getVoucherRedemption()
          } else {
            alert('มีบางอย่างผิดพลาด กรุณาตรวสอบข้อมูลอีกครั้ง!')
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    printVoucher(item) {
      let info = {
        item: item,
        title: this.data.title,
        description: this.data.description,
        value: this.data.value,
        imgUrl: this.data.imgUrl,
        serialNumber: this.data.serialNumber,
      }

      let html = printV.exportVoucher(info)

      let data = {
        ref: this.shopObjectId,
        timezone: 'Asia/Bangkok',
        html: html,
      }
      let name = item.voucherNO + '.pdf'

      report({
        method: 'post',
        url: '/print/v1.0/' + this.uid + '/htmltopdf/a4',
        data: data,
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        this.updatePrinted([item.objectId])
      })
    },
    printAllVoucher() {
      this.loadingButton = false
      let info = {
        item: this.printdata,
        title: this.data.title,
        value: this.data.value,
        total: this.detail.length,
        imgUrl: this.data.imgUrl,
        description: this.data.description,
        serialNumber: this.data.serialNumber,
      }
      // console.log('info', info)
      let html = printV.printAllVoucher(info)

      let data = {
        ref: this.shopObjectId,
        timezone: 'Asia/Bangkok',
        html: html,
      }
      let name = this.data.serialNumber + '.pdf'
      report({
        method: 'post',
        url: '/print/v1.0/' + this.uid + '/htmltopdf/a4',
        data: data,
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        this.updatePrinted(this.objectIds)
      })
    },
    updatePrinted(Ids) {
      let data = {
        objectIds: Ids,
      }

      crm
        .post('/api/v1.0/' + this.uid + '/voucher/updateprinted', data)
        .then((res) => {
          if (res.data.error.code === 0) {
            this.loadingButton = true
            this.getVoucherRedemption()
          } else {
            alert('มีบางอย่างผิดพลาด กรุณาตรวสอบข้อมูลอีกครั้ง!')
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
}
</script>

<style scoped>
.bg {
  padding: 10px;
  background-size: 100% 100%;
  height: 250px;
}
.underline {
  text-decoration: none;
}
.box {
  height: fit-content;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

}
.voucherimg{
  max-height: 260px;
  height: 100%;
  width:100%;
  object-fit:contain;
  
}
</style>
